import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import USP from "../../components/usp"
import HeroSectionAlt from "../../components/heroSectionAlt"
import Testimonials from "../../components/testimonials"

function FaucetRepair() {
  const data = useStaticQuery(graphql`
    query magnoliaImages {
      magnoliaPlumber: file(relativePath: { eq: "magnolia-plumber.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Magnolia Plumbing Company"
        description={`Our company has been providing plumbing services for the Magnolia community for over a decade. We offer all kinds of residential plumbing repairs. `}
      />
      <HeroSectionAlt
        h1={`Magnolia Plumbing`}
        h2={`Your Local Magnolia Master Plumber`}
        h3={`We’re proud to serve residents of Magnolia with quality plumbing services at an affordable price. Our licensed, insured, and knowledgeable team is able to handle any plumbing problem you throw at them. From leaks to clogs and water heaters to toilets, Magnolia residents can rely on us to come through for them.
        `}
        p1={`Experienced plumbers are standing by to learn about your plumbing issue and recommend the best solution so you can get your home up and running again. Reach out for a fast, free quote now.
        `}
        heroImg={data.magnoliaPlumber.childImageSharp.fluid}
        heroImgAltTag={`Magnolia Plumbing Company`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div>
                <p className="text-gray-600 text-lg mb-4">
                  With over 30 years of experience, owner and master plumber Sid
                  Bruton founded Klein Plumbing Services in 2006. The goal from
                  the company’s inception was to provide clear, transparent, and
                  honest plumbing solutions and treat clients like family. After
                  decades of serving the Magnolia area, we’re proud to say we
                  love accomplishing that goal more every day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <USP />
      {/* <Testimonials mainTitle={`Our Customers`} /> */}
      <CTA
        title={`Get a Free Quote Now`}
        buttonTitle={`Talk With a Master Plumber`}
      />
    </Layout>
  )
}

export default FaucetRepair
